import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import UnorderedList from "../../../components/UnorderedList/unordered-list"

const OnlineLaunchpad = () => (
  <Layout>
    <SEO title="Online Launchpad" />
    <HeroInner
      title="Online Launchpad"
      imageAlt="Online Launchpad"
      imagePath="services/tailored-services/online-launchpad/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `You have a product and don’t know how to launch it globally. At Blend-Divine, we can help you launch your products on various E-commerce platforms all over the world. We can also help you start your own E-commerce portal and promote your products to be sellable. Investing in an online store can be a valuable addition to your revenue stream.`,
      ]}
    />
    <div className="container mb40">
      <UnorderedList
        items={[
          `Company registration in different countries (Which is a requirement to sell on popular ecommerce portal in all Countries)`,
          `Media Management`,
          `Order Management (Processing of orders from multiple marketplaces) `,
          `Listing Optimization `,
          `Seller Ratings Management`,
          `Dispute Management`,
          `Sales Boosting`,
          `Managing Promotions and Advertisement`,
          `Sales Report`,
          `FBA Shipment creation and other work related to warehouse.`,
          `Financial Reconciliation `,
        ]}
      />
    </div>
    
  </Layout>
)

export default OnlineLaunchpad
